export const CREATE_DOCUMENT = 'CREATE_DOCUMENT';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const EDIT_DOCUMENT = 'EDIT_DOCUMENT';
export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const RETRIEVE_DOCUMENTS = 'RETRIEVE_DOCUMENTS';
export const ATTACH_TAG = 'ATTACH_TAG';
export const REMOVE_TAG = 'REMOVE_TAG';
export const DOCUMENT_ATTACH_SNIPPET = 'ATTACH_DOCUMENT_SNIPPET';
export const DOCUMENT_REMOVE_SNIPPET = 'REMOVE_DOCUMENT_SNIPPET';
export const DOCUMENT_ATTACH_PARENT = 'ATTACH_DOCUMENT_PARENT';
export const DOCUMENT_REMOVE_PARENT = 'REMOVE_DOCUMENT_PARENT';
export const DOCUMENT_ATTACH_UPLOADFILE = 'ATTACH_DOCUMENT_UPLOADFILE';
export const DOCUMENT_REMOVE_UPLOADFILE = 'REMOVE_DOCUMENT_UPLOADFILE';
export const DOCUMENT_ADD_CANWRITE = 'ADD_DOCUMENT_CANWRITE';
export const DOCUMENT_REMOVE_CANWRITE = 'REMOVE_DOCUMENT_CANWRITE';
export const DOCUMENT_ADD_CANREAD = 'ADD_DOCUMENT_CANREAD';
export const DOCUMENT_REMOVE_CANREAD = 'REMOVE_DOCUMENT_CANREAD';
export const GET_PARENT = 'GET_PARENT';
export const MOVE_DOCUMENT = 'MOVE_DOCUMENT';
export const RETRIEVE_MORE_DOCUMENTS = 'RETRIEVE_MORE_DOCUMENTS';
export const RENAME_DOCUMENT = 'RENAME_DOCUMENT';
export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT';